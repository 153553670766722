import { click_web } from "../../api/webblocks";
import { DEFAULT_IMG } from "../../constant";

const WebEntry = (props: any) => {
  const { instance } = props;
  const { url, title, image, id } = instance;

  const onClick = () => {
    window.open(url);
    click_web(id);
  };

  return (
    <div
      className="group flex items-center cursor-pointer hover:shadow-md p-2 mb-1 rounded-md hover:bg-sky-100"
      onClick={onClick}
      title={title}
    >
      <img
        className="w-8 h-8 mr-4 border border-slate-200 rounded-md"
        src={image || DEFAULT_IMG}
      />
      <div className="truncate text-lg text-black font-medium group-hover:text-blue-500">
        {title}
      </div>
    </div>
  );
};

export default WebEntry;
