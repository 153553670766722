import axios from "axios";

const transformResponse = (result: any) => {
  try {
    return JSON.parse(result);
  } catch (e) {
    console.warn("格式转换错误");
    return null;
  }
};

export const request = async (config: any) => {
  try {
    const result = await axios({
      transformResponse: [transformResponse],
      ...config,
    });
    const { status, data } = result;
    if (status === 200) {
      // 请求成功
      return data;
    } else {
      throw new Error("请求失败");
    }
  } catch (e) {
    console.warn(e);
    return null;
  }
};
