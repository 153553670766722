import { request } from "./request";
import { SERVER_URL } from "../constant";

/**
 * 验证邮箱是否注册或者错误
 * @param email
 * @returns
 */
export const email_verify = async (email: string) => {
  try {
    // const result = await request({
    //   url: `${SERVER_URL}/api/email/verify/${email}`,
    // });
    // return result;
    return await Promise.resolve({
      code: 200,
      message: "验证成功",
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
};

/**
 * 发送邮箱验证码
 * @param email
 * @returns
 */
export const email_code = async (email: string) => {
  try {
    // const result = await request({
    //   url: `${SERVER_URL}/api/email/code`,
    //   method: "post",
    //   data: {
    //     type: 0,
    //     email,
    //   },
    // });
    // return result;
    return await Promise.resolve({
      code: 200,
      message: "发送邮件成功",
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
};

/**
 * 邮箱注册
 * @param info
 * @returns
 */
export const email_register = async (info: any) => {
  try {
    // const { email, password, code } = info;
    // const result = await request({
    //   url: `${SERVER_URL}/api/email/register`,
    //   method: "post",
    //   data: {
    //     email,
    //     password,
    //     code,
    //   },
    // });
    // return result;
    return await Promise.resolve({
      code: 200,
      message: "注册成功",
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
};

/**
 * 邮箱登录
 * @param info
 * @returns
 */
export const email_login = async (info: any) => {
  try {
    // const { email, password } = info;
    // const result = await request({
    //   url: `${SERVER_URL}/api/email/login`,
    //   method: "post",
    //   data: {
    //     email,
    //     password,
    //   },
    // });
    // return result;
    return await Promise.resolve({
      code: 200,
      message: "登录成功",
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
};

/**
 * 重新设置邮箱密码
 * @param info
 * @returns
 */
export const email_reset_password = async (info: any) => {
  try {
    // const { email, password, code } = info;
    // const result = await request({
    //   url: `${SERVER_URL}/api/email/reset_password`,
    //   method: "post",
    //   data: {
    //     email,
    //     password,
    //     code,
    //   },
    // });
    // return result;
    return Promise.resolve({
      code: 200,
      message: "重新设置邮箱密码成功",
    });
  } catch (e) {
    console.warn(e);
    return {};
  }
};
