import WebEntry from "../WebEntry";
import WebEntryWithDesc from "../WebEntryWithDesc";

const WebBlockItem = (props: any) => {
  const { webs, withDesc, dataId } = props;
  const rootClassName = withDesc
    ? `grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-8 mr-4 mb-4`
    : `grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-8 mr-4 mb-4`;
  return (
    <div className={rootClassName}>
      {webs.map((instance: any, index: number) => {
        return withDesc ? (
          <WebEntryWithDesc instance={instance} key={`${dataId}_${index}`} />
        ) : (
          <WebEntry instance={instance} key={`${dataId}_${index}`} />
        );
      })}
    </div>
  );
};

export default WebBlockItem;
