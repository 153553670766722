import { useContext } from "react";
import { Layout, Menu } from "antd";
import { PageContext } from "../../PageContextProvider";
import { WEB_BLOCK_KEY_PREFIX } from "../../constant";
import { scrollToElementWithOffset } from "../../util";

const Header = () => {
  const { title, menuItems } = useContext(PageContext);

  return (
    <Layout.Header className="bg-white shadow-sm flex items-center sticky top-0 z-10 px-4">
      <div className="font-bold text-xl">{title}</div>
      <Menu
        mode="horizontal"
        items={menuItems}
        className="flex-1 border-b-0 min-w-0"
        // style={{ flex: 1, borderBottom: "none" }}
        onClick={({ key }) => {
          scrollToElementWithOffset(`${WEB_BLOCK_KEY_PREFIX}${key}`, 100);
        }}
      />
    </Layout.Header>
  );
};

export default Header;
