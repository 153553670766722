import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import PageContextProvider from "./PageContextProvider";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Reset from "./components/Reset";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

const App: React.FC = () => (
  <PageContextProvider>
    <Layout>
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />} />
        </Routes>
      </Router>
      <Footer />
    </Layout>
  </PageContextProvider>
);

export default App;
