import { Tabs, Badge } from "antd";
import type { TabsProps } from "antd";
import { Link } from "react-router-dom";
import Mail from "./Mail";
import Phone from "./Phone";
import QrCode from "./QrCode";
import { ArrowRightOutlined } from "@ant-design/icons";

const items: TabsProps["items"] = [
  {
    key: "qrCode",
    label: "扫码登录",
    children: <QrCode />,
  },
  {
    key: "phone",
    label: "手机号登录",
    children: <Phone />,
  },
  {
    key: "mail",
    label: "邮箱登录",
    children: <Mail />,
  },
];

const Login = () => {
  return (
    <div className="flex justify-center p-4">
      <Badge.Ribbon
        text={
          <Link to="/register">
            去注册
            <ArrowRightOutlined />
          </Link>
        }
      >
        <div className="p-8 bg-white shadow-sm rounded-md">
          <Tabs defaultActiveKey="mail" size="large" centered items={items} />
        </div>
      </Badge.Ribbon>
    </div>
  );
};

export default Login;
