import { useState } from "react";
import { LockOutlined, MobileOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, Statistic, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { email_register } from "../../../api/login";
import { PHONES_REGS } from "../../../constant";

type PrefixType = keyof typeof PHONES_REGS;

type CustomPhoneNumberType = {
  prefix?: PrefixType;
  number?: string;
};

interface ICustomPhoneNumberProps {
  id?: string;
  value?: CustomPhoneNumberType;
  onChange?: (value: CustomPhoneNumberType) => void;
}

const { Countdown } = Statistic;

const CountdownButton = (props: any) => {
  const { onClick } = props;
  const [disabled, setDisabled] = useState(false);
  const [start, setStart] = useState(false);

  const onInnerClick = async () => {
    const result = await onClick();
    if (result) {
      setStart(true);
      setDisabled(true);
    }
  };

  return (
    <Button disabled={disabled} className="w-36" onClick={onInnerClick}>
      {start && (
        <Countdown
          prefix="("
          suffix="s)"
          format="s"
          value={Date.now() + 30 * 1000}
          valueStyle={{
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "rgba(0, 0, 0, .25)",
          }}
          onFinish={() => {
            setStart(false);
            setDisabled(false);
          }}
        />
      )}
      发送验证码
    </Button>
  );
};

const CustomPhoneNumber = (props: ICustomPhoneNumberProps) => {
  const { id, value = {}, onChange } = props;
  const [prefix, setPrefix] = useState<PrefixType>("+86");
  const [number, setNumber] = useState("");

  const triggerChange = (changedValue: {
    prefix?: PrefixType;
    number?: string;
  }) => {
    onChange?.({ prefix, number, ...value, ...changedValue });
  };

  const onPrefixChange = (newPrefix: PrefixType) => {
    if (!("prefix" in value)) {
      setPrefix(newPrefix);
    }
    triggerChange({ prefix: newPrefix });
  };

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = e.target.value;
    if (!("number" in value)) {
      setNumber(newNumber);
    }
    triggerChange({ number: newNumber });
  };

  return (
    <Space.Compact id={id} direction="horizontal" className="w-full">
      <Select
        style={{ width: "30%" }}
        onChange={onPrefixChange}
        value={value.prefix || prefix}
        options={Object.entries(PHONES_REGS).map((item) => ({
          value: item[0],
          label: item[0],
        }))}
        showSearch
      />
      <Input
        prefix={<MobileOutlined />}
        style={{ width: "70%" }}
        placeholder="请输入正确格式的手机号码"
        onChange={onNumberChange}
        value={value.number || number}
        allowClear
      />
    </Space.Compact>
  );
};

const Phone = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSendCode = () => {
    const emailValue = form.getFieldValue("email");
    if (!emailValue) return Promise.resolve(false);
    const error = form.getFieldError("email");
    return Promise.resolve(!error.length);
  };

  const onSubmit = (values: any) => {
    const { phone, code } = values;
    email_register({ phone, code }).then((result: any) => {
      if (result.code === 200) {
        message.success(result.message);
        navigate("/");
      } else {
        message.error(result.message);
      }
    });
  };

  return (
    <div className="mt-2 h-64 max-w-80 md:w-80">
      <Form
        form={form}
        name="register"
        onFinish={onSubmit}
        initialValues={{
          phone: {
            prefix: "+86",
          },
        }}
        scrollToFirstError
      >
        <Form.Item
          name="phone"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator(_, value: CustomPhoneNumberType) {
                const { prefix, number } = value;
                if (!number) return Promise.reject("手机号码不能为空");
                const phoneNumberReg = PHONES_REGS[prefix!];
                if (phoneNumberReg && phoneNumberReg.test(value.number!)) {
                  return Promise.resolve("");
                } else {
                  return Promise.reject("手机号码格式不正确");
                }
              },
            }),
          ]}
        >
          <CustomPhoneNumber />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator: (_, value) => {
                if (!value) return Promise.reject("请输入验证码");
                const reg = /^\d{6}$/;
                if (reg.test(value)) {
                  return Promise.resolve("");
                } else {
                  return Promise.reject("验证码由6位数字组成");
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Space direction="horizontal">
            <Input placeholder="验证码由6位数字组成" />
            <CountdownButton onClick={onSendCode} />
          </Space>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Phone;
