import { useState } from "react";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, Statistic, message } from "antd";
import { useNavigate } from "react-router-dom";
import { email_verify, email_register } from "../../../api/login";

const { Countdown } = Statistic;

const CountdownButton = (props: any) => {
  const { onClick } = props;
  const [disabled, setDisabled] = useState(false);
  const [start, setStart] = useState(false);

  const onInnerClick = async () => {
    const result = await onClick();
    if (result) {
      setStart(true);
      setDisabled(true);
    }
  };

  return (
    <Button disabled={disabled} className="w-36" onClick={onInnerClick}>
      {start && (
        <Countdown
          prefix="("
          suffix="s)"
          format="s"
          value={Date.now() + 30 * 1000}
          valueStyle={{
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "rgba(0, 0, 0, .25)",
          }}
          onFinish={() => {
            setStart(false);
            setDisabled(false);
          }}
        />
      )}
      发送验证码
    </Button>
  );
};

const Mail = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSendCode = () => {
    const emailValue = form.getFieldValue("email");
    if (!emailValue) return Promise.resolve(false);
    const error = form.getFieldError("email");
    return Promise.resolve(!error.length);
  };

  const onSubmit = (values: any) => {
    const { email, code, password } = values;
    email_register({ email, code, password }).then((result: any) => {
      if (result.code === 200) {
        message.success(result.message);
        navigate("/");
      } else {
        message.error(result.message);
      }
    });
  };

  return (
    <div className="mt-2 h-64 max-w-80 md:w-80">
      <Form form={form} name="register" onFinish={onSubmit} scrollToFirstError>
        <Form.Item
          name="email"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator(_, value) {
                if (!value) return Promise.reject("请输入您的邮箱");
                const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!reg.test(value)) {
                  return Promise.reject("请确认邮箱格式是否正确");
                }
                return new Promise((resolve, reject) => {
                  email_verify(value).then((result: any) => {
                    if (result.code === 200) {
                      resolve("");
                    } else {
                      reject("邮箱已被注册或验证不通过");
                    }
                  });
                });
              },
            }),
          ]}
          hasFeedback
        >
          <Input prefix={<MailOutlined />} placeholder="邮箱" allowClear />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator: (_, value) => {
                if (!value) return Promise.reject("请输入验证码");
                const reg = /^\d{6}$/;
                if (reg.test(value)) {
                  return Promise.resolve("");
                } else {
                  return Promise.reject("验证码由6位数字组成");
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Space direction="horizontal">
            <Input placeholder="验证码由6位数字组成" />
            <CountdownButton onClick={onSendCode} />
          </Space>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator: (_, value) => {
                if (!value) return Promise.reject("请输入密码");
                const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
                if (reg.test(value)) {
                  return Promise.resolve("");
                } else {
                  return Promise.reject(
                    "密码至少由含有大小写字母、数字的8位字符组成"
                  );
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="含有大小写字母、数字，且至少8位"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validateTrigger: ["onChange", "onBlur"],
              validator: (_, value) => {
                if (!value) return Promise.reject("确认密码不能为空");
                if (getFieldValue("password") !== value) {
                  return Promise.reject("前后输入密码不一致");
                }
                return Promise.resolve("");
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="请再次确认密码"
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            注册
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Mail;
