import { useEffect, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import WebBlockItem from "../WebBlockItem";
import { get_web_block } from "../../api/webblocks";

const WebBlock = (props: any) => {
  const { webBlockId, dataId } = props;
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);

  useEffect(() => {
    get_web_block(webBlockId).then((result) => {
      const { web_sets = [], description_type = 0 } = result;
      const items = web_sets.map((item: any, index: number) => {
        const { id, category, webs } = item;
        return {
          key: `${id}`,
          label: category,
          children: (
            <WebBlockItem
              webs={webs}
              withDesc={!!description_type}
              dataId={`${dataId}_${index}_${id}`}
            />
          ),
        };
      });
      setTabItems(items);
    });
  }, []);
  return (
    <Tabs
      defaultActiveKey="1"
      items={tabItems}
      id={dataId}
      className="mb-4 pl-4 shadow-sm rounded bg-white"
    />
  );
};

export default WebBlock;
