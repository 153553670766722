import { createContext, useState, useEffect } from "react";
import { DEFAULT_PAGE_TITLE } from "./constant";
import { get_web_page } from "./api/webblocks";
import { getQueryValue } from "./util";

export const PageContext = createContext({
  title: DEFAULT_PAGE_TITLE,
  menuItems: [],
  pageId: "1",
});

const PageContextProvider = ({ children }: any) => {
  const [pageId, setPageId] = useState("1");
  const [title, setTitle] = useState(DEFAULT_PAGE_TITLE);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const id = getQueryValue("page_id") || "1";
    setPageId(id);
    get_web_page(id).then((result) => {
      const { category, web_blocks } = result;
      setTitle(category);
      const items = web_blocks.map((block: any) => {
        return {
          key: `${block.id}`,
          label: `${block.category}`,
        };
      });
      setMenuItems(items);
    });
  }, []);

  return (
    <PageContext.Provider
      value={{
        pageId,
        title,
        menuItems,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContextProvider;
