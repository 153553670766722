export const getQueryValue = (key: string) => {
  // 获取查询参数字符串
  const queryString = window.location.search;

  // 创建 URLSearchParams 对象
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(key);
};

export const scrollToElementWithOffset = (elementId: string, offset = 0) => {
  const element = document.getElementById(elementId) as HTMLElement;
  if (element) {
    // 获取元素相对于文档顶部的距离
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;

    // 计算偏移后的滚动位置
    const scrollPosition = elementTop - offset;

    // 使用 scrollTo 滚动到计算的位置
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  }
};
