import { Layout } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_RIGHT, DEFAULT_PAGE_EMAIL } from "../../constant";

const Footer = () => {
  return (
    <Layout.Footer className="text-center">
      <div>
        Copyright ©{new Date().getFullYear()} {DEFAULT_PAGE_RIGHT}
      </div>
      <span className="cursor-pointer">
        <MailOutlined className="mr-1" />
        <a href={`mailto:${DEFAULT_PAGE_EMAIL}`} target="_blank">
          联系我们
        </a>
      </span>
    </Layout.Footer>
  );
};

export default Footer;
