import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { email_verify, email_login } from "../../../api/login";

const Mail = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    email_login(values).then((result: any) => {
      if (result.code === 200) {
        message.success(result.message);
        navigate("/");
      } else {
        message.error(result.message);
      }
    });
  };

  return (
    <div className="mt-2 h-64 max-w-80 md:w-80">
      <Form form={form} name="login" onFinish={onSubmit} scrollToFirstError>
        <Form.Item
          name="email"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator(_, value) {
                if (!value) return Promise.reject("请输入您的邮箱");
                const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!reg.test(value)) {
                  return Promise.reject("请确认邮箱格式是否正确");
                }
                return new Promise((resolve, reject) => {
                  email_verify(value).then((result: any) => {
                    if (result.code === 200) {
                      resolve("");
                    } else {
                      reject("邮箱未注册或验证不通过");
                    }
                  });
                });
              },
            }),
          ]}
          hasFeedback
        >
          <Input prefix={<MailOutlined />} placeholder="邮箱" allowClear />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            () => ({
              validateTrigger: ["onChange", "onBlur"],
              validator: (_, value) => {
                if (!value) return Promise.reject("请输入密码");
                const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
                if (reg.test(value)) {
                  return Promise.resolve("");
                } else {
                  return Promise.reject(
                    "密码至少由含有大小写字母、数字的8位字符组成"
                  );
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="含有大小写字母、数字，且至少8位"
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Mail;
