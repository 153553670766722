import { useEffect, useState, useRef } from "react";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ReloadOutlined,
} from "@ant-design/icons";
import type { QRCodeProps } from "antd";
import { Button, Flex, QRCode, Space, Spin } from "antd";

const value = "https://hotnav.cn";

const customStatusRender: QRCodeProps["statusRender"] = (info) => {
  switch (info.status) {
    case "expired":
      return (
        <div>
          <CloseCircleFilled style={{ color: "red" }} /> {info.locale?.expired}
          <p>
            <Button type="link" onClick={info.onRefresh}>
              <ReloadOutlined /> {info.locale?.refresh}
            </Button>
          </p>
        </div>
      );
    case "loading":
      return (
        <Space direction="vertical">
          <Spin />
          <p>加载中...</p>
        </Space>
      );
    case "scanned":
      return (
        <div>
          <CheckCircleFilled style={{ color: "green" }} />{" "}
          {info.locale?.scanned}
        </div>
      );
    default:
      return null;
  }
};

const QrCode = () => {
  const [status, setStatus] = useState<QRCodeProps["status"]>("active");
  const timer = useRef<any>();

  const loadTimer = () => {
    timer.current = setTimeout(() => {
      setStatus("expired");
      clearTimeout(timer.current);
    }, 30 * 1000);
  };

  useEffect(() => {
    setStatus("active");
    loadTimer();
  }, []);

  const onRefresh = () => {
    setStatus("active");
    loadTimer();
  };
  return (
    <div className="flex flex-col items-center h-64 justify-center max-w-80 md:w-80">
      <div className="text-base mb-2">请使用微信扫描二维码登录</div>
      <QRCode
        value={value}
        status={status}
        onRefresh={onRefresh}
        errorLevel="H"
        icon={"./logo192.png"}
        statusRender={customStatusRender}
      />
    </div>
  );
};

export default QrCode;
