import { useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import WebBlock from "../WebBlock";
import { PageContext } from "../../PageContextProvider";
import { WEB_BLOCK_KEY_PREFIX } from "../../constant";

const Content = () => {
  const { menuItems } = useContext(PageContext);
  const [webblockIds, setWebblockIds] = useState<any>([]);

  useEffect(() => {
    if (!menuItems.length) return;
    const web_block_ids = menuItems.map((item: any) => item.key);
    setWebblockIds(web_block_ids);
  }, [menuItems]);

  return (
    <Layout.Content className="p-4">
      {webblockIds.map((blockId: any) => (
        <WebBlock
          webBlockId={blockId}
          key={`${WEB_BLOCK_KEY_PREFIX}${blockId}`}
          dataId={`${WEB_BLOCK_KEY_PREFIX}${blockId}`}
        />
      ))}
    </Layout.Content>
  );
};

export default Content;
