import { request } from "./request";
import { DEFAULT_WEB_PAGE, DEFAULT_WEB_BLOCK, SERVER_URL } from "../constant";

/**
 * 根据pageId获取页面的webblocks集合
 * @param id
 */
export const get_web_page = async (id: string) => {
  try {
    const result = await request({
      url: `${SERVER_URL}/api/web_page/${id}`,
    });
    return result;
  } catch (e) {
    console.warn(e);
    return DEFAULT_WEB_PAGE;
  }
};

/**
 * 获取web_blocks内容
 * @param id
 */
export const get_web_block = async (id: string) => {
  try {
    const result = await request({
      url: `${SERVER_URL}/api/web_block/${id}`,
    });
    return result;
  } catch (e) {
    console.warn(e);
    return DEFAULT_WEB_BLOCK;
  }
};

/**
 * 点击某个网站入口，记录点击
 * @param webId
 * @returns
 */
export const click_web = async (webId: string) => {
  try {
    const result = await request({
      url: `${SERVER_URL}/api/web/click/${webId}/`,
      method: "patch",
    });
    return result;
  } catch (e) {
    console.warn(e);
    return null;
  }
};
